<template>
  <div class="text-left container">
    <!-- Left icons -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header header-elements-inline">
            <h6 class="card-title font-weight-black">Sites > Sitename</h6>
            <div class="header-elements">
              <button class="btn btn-primary">New Site</button>
              <!-- <div class="list-icons">
                <a class="list-icons-item" data-action="collapse"></a>
                <a class="list-icons-item" data-action="reload"></a>
                <a class="list-icons-item" data-action="remove"></a>
              </div> -->
            </div>
          </div>

          <div class="card-body">
            <ul class="nav nav-tabs nav-tabs-highlight">
              <li class="nav-item">
                <a
                  href="#general"
                  class="font-weight-black nav-link active"
                  data-toggle="tab"
                  ><i class="icon-menu7 mr-2"></i> General</a
                >
              </li>

              <li class="nav-item">
                <a
                  href="#theme"
                  class="font-weight-black nav-link"
                  data-toggle="tab"
                  ><i class="icon-text-color mr-2"></i> Theme</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="#email"
                  class="font-weight-black nav-link"
                  data-toggle="tab"
                  ><i class="icon-envelop mr-2"></i> Email</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="#payment"
                  class="font-weight-black nav-link"
                  data-toggle="tab"
                  ><i class="icon-wallet mr-2"></i> Payment Methods</a
                >
              </li>
              <!-- <li class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  ><i class="icon-gear mr-2"></i> Dropdown</a
                >
                <div class="dropdown-menu dropdown-menu-right">
                  <a
                    href="#payment"
                    class="dropdown-item"
                    data-toggle="tab"
                    >Dropdown tab</a
                  >
                  <a
                    href="#left-icon-tab4"
                    class="dropdown-item"
                    data-toggle="tab"
                    >Another tab</a
                  >
                </div>
              </li> -->
            </ul>

            <div class="tab-content">
              <div class="tab-pane fade show active" id="general">
                <form>
                  <div class="form-group row">
                    <label
                      for="siteid"
                      class="
                        p-1
                        col-sm-3 col-md-2 col-form-label
                        font-weight-bold
                      "
                      >Site ID</label
                    >
                    <div class="col-sm-8 col-md-6 p-1">
                      <input
                        type="text"
                        v-model="sitesForm.site_id"
                        class="form-control"
                        disabled
                        id="siteid"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label
                      for="siteid"
                      class="
                        p-1
                        col-sm-3 col-md-2 col-form-label
                        font-weight-bold
                      "
                      >Site Name</label
                    >
                    <div class="col-sm-8 col-md-6 p-1">
                      <input
                        type="text"
                        v-model="sitesForm.name"
                        class="form-control"
                        required
                        id="siteid"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label
                      for="siteid"
                      class="
                        p-1
                        col-sm-3 col-md-2 col-form-label
                        font-weight-bold
                      "
                      >Site Url</label
                    >
                    <div class="col-sm-8 col-md-6 p-1">
                      <input
                        type="url"
                        v-model="sitesForm.url"
                        class="form-control"
                        required
                        id="siteid"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="siteid"
                      class="
                        p-1
                        col-sm-3 col-md-2 col-form-label
                        font-weight-bold
                      "
                      >Application Url</label
                    >
                    <div class="col-sm-8 col-md-6 p-1">
                      <input
                        type="url"
                        v-model="sitesForm.application_url"
                        class="form-control"
                        required
                        id="siteid"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label
                      for="siteid"
                      class="
                        p-1
                        col-sm-3 col-md-2 col-form-label
                        font-weight-bold
                      "
                      >Site Email</label
                    >
                    <div class="col-sm-8 col-md-6 p-1">
                      <input
                        type="email"
                        v-model="sitesForm.email"
                        class="form-control"
                        required
                        id="siteid"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label
                      for="siteid"
                      class="
                        p-1
                        col-sm-3 col-md-2 col-form-label
                        font-weight-bold
                      "
                      >Is Admin website</label
                    >
                    <div class="col-sm-8 col-md-6 p-1">
                      <v-switch
                        class="my-0"
                        hide-details
                        v-model="is_admin_system"
                        inset
                      ></v-switch>
                      <p class="my-1">
                        Enable if this is your default (admin) website
                      </p>
                    </div>
                  </div>

                  <div class="form-group row" v-if="!is_admin_system">
                    <label
                      for="siteid"
                      class="
                        p-1
                        col-sm-3 col-md-2 col-form-label
                        font-weight-bold
                      "
                      >Select admin system/website</label
                    >
                    <div class="col-sm-8 col-md-6 p-1">
                      <vue-select
                        label="name"
                        v-model="sitesForm.admin_system"
                        placeholder="Start typing to search"
                        :options="adminSystems"
                        :reduce="(site) => site.id"
                      ></vue-select>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label
                      for="siteid"
                      class="
                        p-1
                        col-sm-3 col-md-2 col-form-label
                        font-weight-bold
                      "
                      >Client (Belongs to)</label
                    >
                    <div class="col-sm-8 col-md-6 p-1">
                      <vue-select
                        label="first_name"
                        v-model="sitesForm.client_id"
                        placeholder="Start typing to search"
                        :options="clients"
                        :reduce="(client) => client.id"
                      ></vue-select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <h4 class="mb-0">Google Recaptcha Settings</h4>
                      <small class="text-muted">
                        To enable google recaptcha (Verify you are not a robot)
                      </small>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label
                      for="siteid"
                      class="
                        p-1
                        col-sm-3 col-md-2 col-form-label
                        font-weight-bold
                      "
                      >Site Key</label
                    >
                    <div class="col-sm-8 col-md-6 p-1">
                      <input
                        type="text"
                        v-model="sitesForm.site_key"
                        class="form-control"
                        required
                        id="siteid"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label
                      for="siteid"
                      class="
                        p-1
                        col-sm-3 col-md-2 col-form-label
                        font-weight-bold
                      "
                      >Secret Key</label
                    >
                    <div class="col-sm-8 col-md-6 p-1">
                      <input
                        type="text"
                        v-model="sitesForm.secret_key"
                        class="form-control"
                        required
                        id="siteid"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <h4 class="mb-0">Default Setups</h4>
                      <small class="text-muted">
                          These are setups that appear by default on your order form
                      </small>
                    </div>
                  </div>

                  <div class="col-12 offset-sm-3 offset-md-2 px-0 pt-4">
                    <button
                      :disabled="connectionsForm.busy"
                      type="submit"
                      class="btn btn-primary btn-lg text-light"
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
              <!-- ends general settings -->

              <div class="tab-pane fade" id="theme">
                <div class="form-group row">
                  <label
                    for="siteid"
                    class="
                      p-1
                      col-sm-3 col-md-2 col-form-label
                      font-weight-bold
                    "
                    >Background Color</label
                  >
                  <div class="col-sm-8 col-md-6 p-1">
                    <div class="input-group">
                      <input
                        type="color"
                        v-model="sitesForm.backgroundColor"
                        class="form-control border-right-0"
                        placeholder="#234343"
                        value="#234343"
                        style="border-radius: 8px 0 0 8px !important"
                      />
                      <span class="input-group-append">
                        <button
                          class="btn"
                          :style="`background: ${sitesForm.backgroundColor} !important;color: ${sitesForm.textColor} !important;`"
                          type="button"
                        >
                          Color
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="siteid"
                    class="
                      p-1
                      col-sm-3 col-md-2 col-form-label
                      font-weight-bold
                    "
                    >Text Color</label
                  >
                  <div class="col-sm-8 col-md-6 p-1">
                    <div class="input-group">
                      <input
                        type="color"
                        v-model="sitesForm.textColor"
                        class="form-control border-right-0"
                        placeholder="#f4f4f8"
                        value="#f4f4f8"
                        style="border-radius: 8px 0 0 8px !important"
                      />
                      <span class="input-group-append">
                        <button
                          class="btn"
                          :style="`background: ${sitesForm.backgroundColor} !important;color: ${sitesForm.textColor} !important;`"
                          type="button"
                        >
                          Color
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="siteid"
                    class="
                      p-1
                      col-12 col-sm-3 col-md-2 col-form-label
                      font-weight-bold
                    "
                    >Orderform Layout</label
                  >
                  <div class="col-12 col-sm-9 col-md-10 p-1 order-form-layout">
                    <div class="row">
                      <div class="col-12 col-sm-6">
                        <div class="active">
                          <img
                            class="img-fluid"
                            height="160px"
                            src="../../assets/images/layout1.png"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="">
                          <img
                            class="img-fluid"
                            height="160px"
                            src="../../assets/images/layout2.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 offset-sm-3 offset-md-2 px-0 pt-4">
                    <button
                      :disabled="connectionsForm.busy"
                      type="submit"
                      class="btn btn-primary btn-lg text-light"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>

              <!-- ends theme -->

              <div class="tab-pane fade" id="email">
                <form
                  class="flex-fill text-left"
                  ref="connectionsForm"
                  @submit.prevent="createConnection"
                >
                  <div class>
                    <div class="mb-3">
                      <span class="d-block text-muted"
                        >Mail Server Configs</span
                      >
                    </div>

                    <div class="">
                      <div class="form-group row">
                        <label
                          for="siteid"
                          class="
                            p-1
                            col-sm-3 col-md-2 col-form-label
                            font-weight-bold
                          "
                          >SMTP Server</label
                        >
                        <div class="col-sm-8 col-md-6 p-1">
                          <input
                            type="text"
                            v-model="connectionsForm.smtp_server"
                            class="form-control"
                            required
                            id="siteid"
                          />
                        </div>
                      </div>
                      <div class="form-group row">
                        <label
                          for="siteid"
                          class="
                            p-1
                            col-sm-3 col-md-2 col-form-label
                            font-weight-bold
                          "
                          >SMTP User</label
                        >
                        <div class="col-sm-8 col-md-6 p-1">
                          <input
                            type="text"
                            v-model="connectionsForm.smtp_user"
                            class="form-control"
                            required
                            id="siteid"
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label
                          for="siteid"
                          class="
                            p-1
                            col-sm-3 col-md-2 col-form-label
                            font-weight-bold
                          "
                          >SMTP Password</label
                        >
                        <div class="col-sm-8 col-md-6 p-1">
                          <input
                            type="text"
                            v-model="connectionsForm.smtp_password"
                            class="form-control"
                            required
                            id="siteid"
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label
                          for="siteid"
                          class="
                            p-1
                            col-sm-3 col-md-2 col-form-label
                            font-weight-bold
                          "
                          >Port</label
                        >
                        <div class="col-sm-8 col-md-6 p-1">
                          <input
                            type="text"
                            v-model="connectionsForm.smtp_port"
                            class="form-control"
                            required
                            id="siteid"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-12 offset-sm-3 offset-md-2 px-0 pt-4">
                      <button
                        :disabled="connectionsForm.busy"
                        type="submit"
                        class="btn btn-primary btn-lg text-light"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <!-- ends email settings -->

              <div class="tab-pane fade" id="payment">
                <div class="form-group row">
                  <label
                    for="siteid"
                    class="
                      p-1
                      col-sm-3 col-md-2 col-form-label
                      font-weight-bold
                    "
                    >Vortex Checkout URl</label
                  >
                  <div class="col-sm-8 col-md-6 p-1">
                    <input
                      type="text"
                      v-model="sitesForm.gateway_checkout_url"
                      class="form-control"
                      required
                      id="siteid"
                    />
                  </div>
                </div>
                <div class="col-12 offset-sm-3 offset-md-2 px-0 pt-4">
                  <button
                    :disabled="connectionsForm.busy"
                    type="submit"
                    class="btn btn-primary btn-lg text-light"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
              <!-- ends payment settings -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /left icons -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      is_admin_system: false,
    };
  },
  computed: {
    ...mapState("sites", [
      "sitesForm",
      "loading",
      "sites",
      "headers",
      "adminSystems",
    ]),
    ...mapState("clients", ["clients"]),
    ...mapState("connections", ["connectionsForm", "connections", "headers"]),
  },
  methods: {
    ...mapActions("sites", ["getSites", "getGetAdminSystems"]),
    ...mapActions("clients", ["getClients"]),

    editMode(item) {
      this.activeItem = item;
      Object.assign(this.sitesForm, { ...item });
      this.is_admin_system =
        this.sitesForm.is_admin_system == "-1" ? false : true;
      this.sitesForm.site_id = item.id;
      this.isEditMode = true;
      this.$store.state.dialog = true;
    },
  },
  async mounted() {
    await this.getSites();
    await this.getClients();
    await this.getGetAdminSystems();
  },
};
</script>

<style lang="scss">
.order-form-layout {
  .active img {
    border: 1px solid #2096f3 !important;
    box-shadow: 0 0 0 4px rgb(33 153 232 / 25%);
    border-radius: 8px;
  }
}
</style>